<template>
  <Button size="small" type="button" v-bind="buttonProps" @click="onClick">
    <template #icon v-if="isAuthenticated">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="white">
        <!-- !Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
        <path
          d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
      </svg>
    </template>
    <template v-if="show_spinner">
      <ProgressSpinner />
    </template>
    <template v-else-if="!isAuthenticated">
      Log In
    </template>
  </Button>
  <template v-if="isAuthenticated">
    <Menu ref="menu" id="main_menu" :model="items" :popup="true" />
  </template>
</template>
<script>
import { jwtDecode } from "jwt-decode";

export const STORAGE_KEYS = {
  STATE: 'ACCESS_STATE',
  TOKEN: 'ACCESS_TOKEN'
};

export default {
  name: "UserMenu",
  computed: {
    show_spinner() {
      return this.pathname === '/continue_auth' || this.isLoading;
    },
    app_metadata() {
      const user = this.user;
      const app_metadata = user && user[process.env.VUE_APP_AUTH0_APP_META_KEY];
      return app_metadata;
    },
    items() {
      if (this.isAuthenticated) {
        const { billing_portal_url } = this.app_metadata || {};

        return ([
          !!billing_portal_url && {
            label: `Manage subscription`,
            url: billing_portal_url
          },
          {
            label: `Logout`,
            command: this.logout
          }
        ]).filter(v => v);
      }
      return [];
    },
    onClick() {
      if (this.isAuthenticated) {
        return this.toggle;
      }
      else if (this.show_spinner) {
        return () => { };
      }
      else {
        return this.login;
      }
    },
    buttonProps() {
      if (this.isAuthenticated) {
        return {
          icon: "pi pi-bars",
          'aria-haspopup': true,
          'aria-label': "Main Menu",
          'aria-controls': "main_menu",
        };
      } else if (this.show_spinner) {
        return {
          label: "Loading",
          disabled: "true",
        };
      } else {
        return {
          'aria-label': "Log In",
        };
      }
    },
  },
  created() {
    if (this.pathname === '/continue_auth') {
      const currentParams = new URLSearchParams(window.location.search);
      const stateParam = currentParams.get('state');
      const sessionTokenParam = currentParams.get('session_token');

      if (stateParam && sessionTokenParam) {
        localStorage.setItem(STORAGE_KEYS.STATE, stateParam)
        localStorage.setItem(STORAGE_KEYS.TOKEN, sessionTokenParam)

        const decodedToken = jwtDecode(sessionTokenParam);

        if (decodedToken.customer_type === 'new') {
          window.location.replace(decodedToken.checkout_url);
        } else {
          window.location.replace(decodedToken.billing_portal_url);
        }
      } else {
        const state = localStorage.getItem(STORAGE_KEYS.STATE);
        const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
        localStorage.removeItem(STORAGE_KEYS.STATE);
        localStorage.removeItem(STORAGE_KEYS.TOKEN);

        if (token && state) {
          const decodedToken = jwtDecode(token);

          window.location.replace((`${decodedToken.continue_uri}?state=${state}`));
        } else {
          window.location.replace('/');
        }
      }
    }
    else {
      localStorage.removeItem(STORAGE_KEYS.STATE);
      localStorage.removeItem(STORAGE_KEYS.TOKEN);
    }
  },
  data() {
    return {
      urls: {
        catalogViewer: process.env.VUE_APP_CATALOG_VIEWER_URL
      },
      pathname: window.location.pathname,
      isLoading: this.$auth0.isLoading,
      isAuthenticated: this.$auth0.isAuthenticated,
      showSpinner: this.$auth0.isLoading,
      user: this.$auth0.user,
    };
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    logout() {
      this.$auth0.logout({ logoutParams: { returnTo: window.location.origin } });
    },
    login() {
      this.$auth0.loginWithRedirect();
    },
  },
};
</script>
