import { createApp } from 'vue';
import { createAuth0 } from '@auth0/auth0-vue';
import App from './App.vue';
import UserMenu from './components/UserMenu.vue';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import ProgressSpinner from 'primevue/progressspinner';
import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css'
import presetTheme from './tailwind-presets/college-search';
import './index.css'

const app = createApp(App);
app
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    })
  )
  .use(PrimeVue, {
    unstyled: true,
    pt: presetTheme,
  })
  .component(UserMenu.name, UserMenu)
  .component(Menu.name, Menu)
  .component(Button.name, Button)
  .component(ProgressSpinner.name, ProgressSpinner)
  .mount('#app')
